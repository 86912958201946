<template>
  <v-autocomplete class="c-input-xs input-filter" :class="{ 'c-input-has-sort': hasSort }" v-model="value"
    :items="disabledFilter ? [] : options" :label="label" :placeholder="placeholder" :prepend-inner-icon="sortPrependIcon"
    @click:prepend-inner="sortClick" dense outlined clearable hide-details single-line></v-autocomplete>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: 'SelectEmployee',
  props: {
    hasSort: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => ''
    },
    sortName: {
      type: String,
      default: () => ''
    },
    sorting: {
      type: String,
      default: () => ''
    },
    defaultSort: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    filters: {
      type: Object,
      default: () => { }
    },
    url: {
      type: String,
      default: () => '/employee-active-my-pos'
    },
    disabledFilter: {
      type: Boolean,
      default: () => false
    },
    /*options: {
      type: Array,
      default: () => []
    },*/
  },
  data: () => ({
    value: null,
    sortValue: null,
    options: []
  }),
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return ''
      }
      switch (this.sortValue) {
        case 'asc':
          return 'mdi-arrow-up'
        case 'desc':
          return 'mdi-arrow-down'
        default:
          return 'mdi-arrow-up-down'
      }
    }
  },
  watch: {
    value() {
      this.onFilter()
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null
      }
    }
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit('onFilter', {
        name: this.name,
        value: this.value
      })
    }, 500),
    async getList() {
      const { data } = await httpClient.post(this.url, this.filters)
      /* this.options = data.map(e => ({
        value: e.id_identity,
        text: e.full_name || ''
      })) */
      const options = []
      data.forEach(item => {
        if (![39, 152, 208, 488, 805].includes(item.id_identity)) {
          options.push({
            value: item.id_identity,
            text: item.full_name || ''
          })
        }
      })
      this.options = [...options]
    },
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = 'asc'
      } else if (this.sortValue === 'asc') {
        this.sortValue = 'desc'
      } else if (this.sortValue === 'desc') {
        this.sortValue = 'asc'
      }
      this.$emit('onSort', {
        sort_by: this.sortName,
        sort_type: this.sortValue
      })
    }
  },
  mounted() {
    this.getList()
    if (this.defaultSort) {
      this.sortValue = this.defaultSort
    }
  }
}
</script>
